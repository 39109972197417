<template>
  <div>
    <contractors-edit></contractors-edit>
  </div>
</template>

<script>
import ContractorsEdit from "@/components/ecologist/classifiers/contractors/contractorsEdit";
export default {
  name: "contractorsEditView",
  components: { ContractorsEdit },
};
</script>

<style scoped></style>

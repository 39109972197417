<template>
  <div>
    <el-form
      :model="selectedContractors"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Наименование">
            <el-select
              style="width: 100%"
              :disabled="typeWriteTitle"
              class="searchPole"
              clearable
              v-model="selectedContractors.egr"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Полное наименование"
              :remote-method="getEgrNames"
            >
              <el-option
                v-for="item in egrNamesList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-checkbox @change="changeTypeWriteTitle" v-model="typeWriteTitle"
              >Ручной ввод наименования</el-checkbox
            >
            <el-input
              :disabled="!typeWriteTitle"
              v-model="selectedContractors.nameManual"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Местонахождение (юридический адрес)">
            <el-input
              v-if="typeWriteTitle"
              v-model="selectedContractors.addressManual"
            ></el-input>
            <p v-else>{{ selectedContractors.addressManual }}</p>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Телефон, факс">
            <el-input
              v-if="typeWriteTitle"
              v-model="selectedContractors.phoneNumberManual"
            ></el-input>
            <p v-else v-html="selectedContractors.phoneNumberManual"></p>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="Номер договора">
            <el-input v-model="selectedContractors.contractNumber"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="9">
          <el-form-item label="Дата подписания договора">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="selectedContractors.contractDateFrom"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="Дата окончания договора">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="selectedContractors.contractDateTo"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="ФИО ответственного лица, телефон">
            <el-input
              v-model="selectedContractors.responsiblePersonNamePhone"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button @click="save" type="primary">Изменить</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "contractorsEdit",
  props: ["selectedContractors"],
  data() {
    return {
      typeWriteTitle: false,
    };
  },

  computed: {
    ...mapGetters({
      egrNamesList: "GETEGRNAMESLIST",
    }),
  },

  methods: {
    changeTypeWriteTitle() {
      if (this.typeWriteTitle) {
        this.selectedContractors.egrId = null;
      } else {
        this.selectedContractors.nameManual = null;
      }
    },

    save() {
      this.changeTypeWriteTitle();
      if (!this.typeWriteTitle) {
        if (this.selectedContractors.egr !== undefined) {
          this.selectedContractors.egrId = this.selectedContractors.egr.id;
          this.selectedContractors.nameManual =
            this.selectedContractors.egr.name;
        }
      }
      console.log("-----");
            console.log(this.selectedContractors);
            console.log("-----");
      this.$store
        .dispatch("updateContractor", this.selectedContractors)
        .then((response) => {
          console.log(response);
          this.notification("Успешно", "Контрагент изменен", "success");
          this.$store.dispatch("setShowModal", null);
          this.$store.dispatch("getAllContractors", { page: 0, size: 20 });
        });
    },

    getEgrNames(query) {
      this.$store.dispatch("getEgrNames", { query: query });
    },
  },
  mounted() {
    if (this.selectedContractors.nameManual != null)
    {
      console.log(this.selectedContractors);
      this.typeWriteTitle = true;
      this.changeTypeWriteTitle();
      console.log(this.selectedContractors);
    }
  },
};
</script>

<style scoped></style>
